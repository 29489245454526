import axios, {AxiosInstance} from 'axios';
import {API_BASE_URL} from "../helpers/Constants";

export default class BaseRequest {

    protected axiosInstance: AxiosInstance;

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: API_BASE_URL,
            withCredentials: true
        });

    }
}
