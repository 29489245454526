import * as React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Find the root element in the DOM
const rootElement = document.getElementById('root');

// Create a root
const root = createRoot(rootElement);

// Initial render
root.render(<App />);

// Unregister the service worker
serviceWorker.unregister();
