import React, {useEffect, useState, createContext, useContext} from "react";
import userRequests, {IUser} from "../requests/UserRequests";
import {redirect} from "react-router-dom";

interface AuthContextType {
    user: IUser | null;
    loading: boolean;
    signIn: (email: string, password: string, isRememberMeChecked: boolean) => Promise<IUser | undefined>;
    signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [user, setUser] = useState<IUser | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            try {
                const user = await userRequests.retrieveLoggedUser()
                setUser(user);
            } catch (e) {
                setUser(null);
                redirect("/sign-in");
            } finally {
                setLoading(false);
            }
        };
        init();
    }, []);


    const signIn = async (email: string, password: string, isRememberMeChecked: boolean): Promise<IUser | undefined> => {
        try {
            const user = await userRequests.signIn(email, password, isRememberMeChecked);
            setUser(user);
            return user;
        } catch (e) {
            console.log(e);
            setUser(null);
            throw e;
        }
    }

    const signOut = async (): Promise<void> => {
        await userRequests.signOut();
        setUser(null);
    }

    return (<AuthContext.Provider value={{user, loading, signIn, signOut}}>
            {children}
        </AuthContext.Provider>
    );
};
